/**
 * Fakturierung
 * API für Fakturierung
 *
 * The version of the OpenAPI document: ${project.version}
 * Contact: support@landdata.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BasePageableDTO } from './base-pageable-dto';
import { PageableFakturierungsbelegAllOfDTO } from './pageable-fakturierungsbeleg-all-of-dto';


/**
 * Gibt das OffSet, Limit und die Sortierung für das Abrufen von Produkten an. 
 */
export interface PageableProduktDTO { 
    /**
     * Wird bei GET-Funktionen genutzt, um anzugeben, wie viele Werte übersprungen werden sollen.
     */
    offset?: number;
    /**
     * Wird bei GET-Funktionen genutzt, um die Anzahl der zurückgegebenen Werte zu begrenzen
     */
    limit?: number;
    sorting?: Array<PageableProduktDTOSortingEnum>;
}
export enum PageableProduktDTOSortingEnum {
    Erstelltasc = 'ERSTELLTASC',
    Erstelltdesc = 'ERSTELLTDESC'
};



