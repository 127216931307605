<div class="table-cell-status-wrapper">
        <span
          [class]="[
          'status-icon',
          data.status.toLowerCase().replace(' ', '-'),
          ]"></span>
  <jf-icon
    [name]="'circle'"
    [variant]="'fill'"
    [class]="[
            'status-icon',
            data.status.toLowerCase().replace(' ', '-'),
            ]"
  ></jf-icon>
  {{ data.status }}
</div>
