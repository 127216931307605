import {AppState} from '../states/app.state';
import {identity} from 'rxjs';
import {createSelector} from '@ngrx/store';


export class DeleteAbsenderDialogSelectors {

  /**
   * Die `select`-Eigenschaft ist ein Objekt, das Funktionen definiert, jede Funktion selektiert eine
   * bestimmte Eigenschaft aus dem State.
   * Das sind grundlegende Selektoren, die im ganzen Projekt als Bausteine für komplexere Selektoren wiederverwendet
   * werden können.
   */
  private static select = {
    isOpen: (state: AppState) => state.deleteAbsenderDialog.isOpen,
    inhaberId: (state: AppState) => state.deleteAbsenderDialog.inhaberId,
    absenderId: (state: AppState) => state.deleteAbsenderDialog.absenderId,
  };

  public static isDeleteAbsenderDialogOpen = createSelector(
    this.select.isOpen,
    identity,
  );

  public static deleteData = createSelector(
    this.select.inhaberId,
    this.select.absenderId,
    (inhaberId, absenderId) => {
      return {
        inhaberId,
        absenderId,
      };
    }
  );
}
