<div class="table-wrapper-page {{useMultisort ? 'multisort' : 'singlesort'}}">
  <div class="table-wrapper">
    <app-table
      [tableSettingsFallback]="tableSettingsFallback"
      [tableSettings]="tableSettings"
      [displayedItems]="displayedItems"
      [tableCells]="tableCells"
      [useMultisort]="useMultisort"
      (changedTableSettings)="changedTableSettingsHandler($event)"
    >
    </app-table>
  </div>
</div>
