import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormInput} from './form-input.interface';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {ButtonModule} from '@adnova/jf-ng-components';


@Component({
  selector: 'app-form',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatDividerModule, ButtonModule],
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent {


  //#region Inputs and Outputs

  private _formInput?: FormInput;

  @Output()
  cancelClicked = new EventEmitter();

  //#endregion Inputs and Outputs

  //#region Getters and Setters

  @Input()
  get formInput(): FormInput {
    return this._formInput!;
  }

  set formInput(value: FormInput) {
    this._formInput = value;
  }

  //#endregion Getters and Setters

  doClickCancel() {
    this.cancelClicked.emit();
  }
}
