/**
 * Fakturierung
 * API für Fakturierung
 *
 * The version of the OpenAPI document: ${project.version}
 * Contact: support@landdata.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Einheit des Produkts
 */
export interface EinheitDTO { 
    /**
     * Generierter Universally Unique Identifier
     */
    einheitId: string;
    nummer: number;
    kurzbezeichnung: string;
    bezeichnung: string;
    mengenart: EinheitDTOMengenartEnum;
}
export enum EinheitDTOMengenartEnum {
    Stueckmenge = 'STUECKMENGE',
    Gewichtsmenge = 'GEWICHTSMENGE'
};



