<jf-button
  class="close-button"
  variant="ghost"
  icon="close"
  iconPosition="icon-only"
  (click)="closeDialogClick()"
></jf-button>

<h2 class="headline">
  Erstelle jetzt hier deine erste Rechnung und profitiere von folgenden Vorteilen:
</h2>

<div class="content">
  <ul class="benefits-list">
    <!-- TODO: Add plus Icon to Component Library -->
    <li
      *ngFor="let benefit of benefitsList"
      class="benefits-list-item"
    >
      <jf-icon name="submit" [size]=24></jf-icon>
      <span>{{benefit.text}}</span>
    </li>
  </ul>

  <jf-button
    variant="primary"
    (click)="redirectFakturierungClick()"
  >Erste E-Rechnung erstellen</jf-button>

  <small>
    * Du bist für die Vollständigkeit und Richtigkeit der eingegebenen Daten verantwortlich – bitte überprüfe diese vor dem Versenden.
  </small>
</div>
