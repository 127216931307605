import {createAction, props} from '@ngrx/store';
import {TableSettings} from '../../interfaces/table-setting.interface';
import {PageableAbsenderDTO} from '../../openapi/fakturierung-openapi';


export class AbsenderTableActions {

  //FIXME: props sorting sollte vom type PageableBelegDTOSortingEnum sein
  public static updateSortingEnum = createAction(
    '[Absender Table] Updates the sorting enum.',
    props<{
      sorting: any[],
    }>(),
  );

  public static changeTableSettings = createAction(
    '[Absender Table] Changed table settings.',
    props<{ tableSettings: TableSettings }>(),
  );

  public static changePage = createAction(
    '[Absender Table] Changed pageable.',
    props<{ pageable: PageableAbsenderDTO }>(),
  );

  public static updateSearchValue = createAction(
      '[Absender Table] Changed search value',
      props<{ searchValue: string[] }>(),
  );

}
