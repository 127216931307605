import {fakturierungsbelegEntitiesAdapter} from '../adapters/fakturierungsbeleg-entities.adapter';
import {createSelector, MemoizedSelector} from '@ngrx/store';
import {AppState} from '../states/app.state';
import {FakturierungsbelegDTO} from '../../openapi/fakturierung-openapi';
import {identity} from 'rxjs';


export class FakturierungsbelegEntitiesSelectors {

  private static adapterSelectors = fakturierungsbelegEntitiesAdapter.getSelectors();

  /**
   * Die `select`-Eigenschaft ist ein Objekt, das Funktionen definiert, jede Funktion selektiert eine
   * bestimmte Eigenschaft aus dem State.
   * Das sind grundlegende Selektoren, die im ganzen Projekt als Bausteine für komplexere Selektoren wiederverwendet
   * werden können.
   */
  private static select = {
    belegEntities: (state: AppState) => state.fakturierungsbelegEntities,
    count: (state: AppState) => state.fakturierungsbelegEntities.count,
  };

  public static all = createSelector(
    this.select.belegEntities,
    this.adapterSelectors.selectAll,
  );

  public static fakturierungsbelegById = (fakturierungsbelegId: string) => createSelector(
    this.all,
    belege => belege.find(beleg => {
      return beleg.id === fakturierungsbelegId;
    }),
  );

  /**
   * Selektor, der Fakturierungsbelege anhand einer Liste von IDs zurückgibt und dabei deren Reihenfolge beibehält.
   *
   * @param {string} inhaberId - Die ID des Inhabers der Belege.
   * @param {string[]} fakturierungsbelegIds - Ein Array von BelegIDs.
   * Die Reihenfolge dieses Arrays bestimmt die Reihenfolge der zurückgegebenen Fakturierungsbelege.
   *
   * @returns {MemoizedSelector<AppState, FakturierungsbelegDTO[]>} Ein Selektor, der ein Array von Belegen zurückgibt, die den
   * angegebenen IDs entsprechen, in der in `belegIds` angegebenen Reihenfolge für den ausgewählten Inhaber.
   * Gibt ein leeres Array zurück, wenn keine übereinstimmenden Fakturierungsbelege gefunden wurden.
   */
  public static fakturierungsbelegeByIds = (fakturierungsbelegIds: string[]): MemoizedSelector<AppState, FakturierungsbelegDTO[]> => createSelector(
    this.all,
    belegDtos => {
      const belegMap = new Map(belegDtos.map(belegDto => [belegDto.id, belegDto]));
      return fakturierungsbelegIds
        .map(id => belegMap.get(id))
        .filter((belegDto): belegDto is FakturierungsbelegDTO => !!belegDto);
    }
  );

  public static fakturierungsbelegeByInhaberId = (inhaberId: string) => createSelector(
    this.all,
    belegDtos => belegDtos.filter(belegDto => belegDto && belegDto.inhaberId === inhaberId),
  );

  public static count = createSelector(
    this.select.count,
    identity,
  );

}
