import {createSelector, MemoizedSelector} from '@ngrx/store';
import {AppState} from '../states/app.state';
import {ProduktDTO} from '../../openapi/fakturierung-openapi';
import {produktEntitiesAdapter} from '../adapters/produkt-entities.adapter';


export class ProduktEntitiesSelectors {

  private static adapterSelectors = produktEntitiesAdapter.getSelectors();

  private static select = {
    produktEntities: (state: AppState) => state.produktEntities,
    count: (state: AppState) => state.produktEntities.count,
    nextProduktnummer: (state: AppState) => state.produktEntities.nextProduktnummer,
  };

  public static all = createSelector(
    this.select.produktEntities,
    this.adapterSelectors.selectAll,
  );

  public static produktById = (produktId: string) => createSelector(
    this.all,
    produktList => produktList.find(produkt => {
      return produkt.id === produktId;
    }),
  );

  public static produkteByIds = (produktIds: string[]): MemoizedSelector<AppState, ProduktDTO[]> => createSelector(
    this.all,
    produktDtos => {
      const produktMap = new Map(produktDtos.map(produktDto => [produktDto.id, produktDto]));
      return produktIds
        .map(id => produktMap.get(id))
        .filter((produktDto): produktDto is ProduktDTO => !!produktDto);
    }
  );

  public static produkteByInhaberId = (inhaberId: string) => createSelector(
    this.all,
    produktList => produktList.filter(produkt => produkt && produkt.inhaberId === inhaberId),
  );

  public static count = createSelector(
    this.select.count,
    count => count,
  );

  public static produktnummern = createSelector(
    this.all,
    produkte => produkte.map(produkt => produkt.produktnummer),
  );

  public static nextProduktnummer = createSelector(
    this.select.nextProduktnummer,
    nextProduktnummer => nextProduktnummer,
  );

}
