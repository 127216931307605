import {Component} from '@angular/core';
import {Store} from '@ngrx/store';
import {BelegeEmptyStateDialogActions} from '../../../store/actions/belege-empty-state-dialog.actions';
import {AppState} from '../../../store/states/app.state';

@Component({
  selector: 'app-empty-belege-dialog',
  templateUrl: './empty-belege-dialog.component.html',
  styleUrls: ['./empty-belege-dialog.component.scss']
})
export class EmptyBelegeDialogComponent {
  protected readonly benefitsList = [
    {
      text: 'Zukunftssicher mit E-Rechnungen',
    },
    {
      text: 'Unterstützt bei der Erstellung rechtskonformer* Rechnungen',
    },
    {
      text: 'Aktuelle branchenspezifische USt-Sätze',
    },
    {
      text: 'Direkte Übertragung von Rechnungen in Belege Online',
    },
  ];

  constructor(
    private store: Store<AppState>,
  ) {
  }

  closeDialogClick(): void {
    this.store.dispatch(BelegeEmptyStateDialogActions.close());
  }

  redirectFakturierungClick(): void {
  }
}
