import {TableSettings} from '../../interfaces/table-setting.interface';
import {FilterAbsenderDTO, PageableAbsenderDTO} from '../../openapi/fakturierung-openapi';


export enum AbsenderColumn {
  NameVorname = 'nameVorname',
  Firma = 'firma',
  Adresse = 'adresse',
  Actions = 'actions',
}

export interface AbsenderTableState {
  displayedIds: string[];
  tableSettings: TableSettings;
  pageable: PageableAbsenderDTO;
  filter: FilterAbsenderDTO;
}
