<!-- INFO: Zahnrad-Icon, das bei einem Klick den Einstellungsdialog für die Spalten öffnet. -->
<mat-multi-sort-table-settings
  *ngIf="table"
  [tableData]="table"
  sortToolTip="Sortierreihenfolge ändern"
  [closeDialogOnChoice]="false"
>
  <button
    mat-icon-button
    matTooltip="Spalten bearbeiten"
    matTooltipPosition="left"
    aria-label="Spalten bearbeiten"
  >
    <jf-icon [size]=16 [strokeWidth]=1.5 name="settings" stroke="white"></jf-icon>
  </button>
</mat-multi-sort-table-settings>

<!-- INFO: Die eigentliche Tabelle und ihre Inhalte. -->
<div class="table-container" #tableContainer>
  <table
    class="{{useMultisort ? 'multisort' : 'singlesort'}}"
    mat-table
    matMultiSort
    [dataSource]="displayedItems"
    (matSortChange)="table?.onSortEvent(); onSortData()">

    <ng-container
      *ngFor="let tableColumn of tableSettingsFallback?._columns"
      [matColumnDef]="tableColumn.id">
      <ng-container *ngIf="tableColumn.sortable !== false">
        <th
          *matHeaderCellDef
          [mat-multi-sort-header]="tableColumn.id"
          mat-header-cell
          [jf-tooltip]="tableColumn.name"
          class="
            table-cell
            header-align-{{tableColumn.alignHeader || 'left'}}
            header-width-{{tableColumn.width}}
          "
          i18n
          [attr.id]="tableColumn.lastCol ? 'lastTableCol' : null"
        >{{tableColumn.name}}
        </th>
      </ng-container>
      <ng-container *ngIf="tableColumn.sortable === false">
        <th
          *matHeaderCellDef
          mat-header-cell
          [jf-tooltip]="tableColumn.name"
          class="
            table-cell
            header-align-{{tableColumn.alignHeader || 'left'}}
            header-width-{{tableColumn.width}}
          "
          i18n
          [attr.id]="tableColumn.lastCol ? 'lastTableCol' : null"
        >{{tableColumn.name}}
        </th>
      </ng-container>

      <td
        class="
          cell-align-{{tableColumn.alignCell || 'left'}}
          {{tableColumn.sortable === false ? 'not-sortable' : 'is-sortable'}}
          cell-width-{{tableColumn.width}}
          cell-line-break-{{tableColumn.overflow || 'ellipsis'}}
        "
        *matCellDef="let element"
        mat-cell
      >
        <ng-container [ngComponentOutlet]="tableCells?.get(tableColumn.id) || null" [ngComponentOutletInjector] = "getCellInjector(element, tableColumn.id)" />
      </td>
    </ng-container>

    <ng-container>
      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns;"
      ></tr>
    </ng-container>

  </table>
</div>
