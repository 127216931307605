import {DeleteAbsenderDialogState} from '../states/delete-absender-dialog.state';
import {createReducer, on} from '@ngrx/store';
import {DeleteAbsenderDialogActions} from '../actions/delete-absender-dialog.actions';


export const initialDeleteAbsenderDialogState: DeleteAbsenderDialogState = {
  isOpen: false,
};

export const deleteAbsenderDialogReducer = createReducer(
  initialDeleteAbsenderDialogState,

  on(
    DeleteAbsenderDialogActions.open,
    (state, action) => ({
      ...state,
      isOpen: true,
      absenderId: action.absenderId,
    })
  ),

  on(
    DeleteAbsenderDialogActions.close,
    () => ({
      ...initialDeleteAbsenderDialogState,
    })
  ),
);
