import {EntityState} from '@ngrx/entity';
import {AbsenderDTO} from '../../openapi/fakturierung-openapi';


type AbsenderDTOEntityState = EntityState<AbsenderDTO> & { ids: string[] };

export const absenderEntitiesMock: AbsenderDTOEntityState = {
  entities: {
    '1': {
      id: '1',
      inhaberId: '2',
      kundennummer: 1234567890,
      anrede: 'Herr',
      nachname: 'Mustermann',
      vorname: 'Max',
      firma: 'Max Mustermann GmbH',
      bezeichnung: 'Max Mustermann GmbH',
      emailAdresse: 'max@mustermann.de',
      hausnummer: '10a',
      internetaddresse: 'www.mustermann.de',
      land: 'Deutschland',
      mobilnummer: '017612345678',
      ort: 'Berlin',
      plz: '10115',
      steuernummer: '123/456/7890',
      strasse: 'Musterstraße',
      telefonnummer: '0301234567',
      titel: 'Dr.',
      ustId: 'DE123456789'
    },
    '2': {
      id: '2',
      inhaberId: '2',
      kundennummer: 9876543210,
      anrede: 'Frau',
      nachname: 'Schmidt',
      vorname: 'Anna',
      firma: 'Anna Schmidt AG',
      bezeichnung: 'Anna Schmidt AG',
      emailAdresse: 'anna@schmidt.de',
      hausnummer: '55b',
      internetaddresse: 'www.schmidt.de',
      land: 'Deutschland',
      mobilnummer: '017698765432',
      ort: 'München',
      plz: '80331',
      steuernummer: '321/654/9876',
      strasse: 'Hauptstraße',
      telefonnummer: '0891234567',
      titel: 'Prof.',
      ustId: 'DE987654321'
    },
    '3': {
      id: '3',
      inhaberId: '2',
      kundennummer: 2345678901,
      anrede: 'Herr',
      nachname: 'Eisen',
      vorname: 'Björn',
      firma: 'Björn Eisen',
      bezeichnung: 'Björn Eisen',
      emailAdresse: 'bjoern@eisenindustrie.de',
      hausnummer: '12c',
      internetaddresse: 'www.eisenindustrie.de',
      land: 'Deutschland',
      mobilnummer: '01601234567',
      ort: 'Frankfurt',
      plz: '60313',
      steuernummer: '222/333/4444',
      strasse: 'Eisenweg',
      telefonnummer: '0691234567',
      titel: 'Dipl.-Ing.',
      ustId: 'DE234567890'
    },
    '4': {
      id: '4',
      inhaberId: '2',
      kundennummer: 3456789012,
      anrede: 'Herr',
      nachname: 'Zeiss',
      vorname: 'Carl',
      firma: 'Carl Zeiss AG',
      bezeichnung: 'Carl Zeiss AG',
      emailAdresse: 'info@zeiss.de',
      hausnummer: '100',
      internetaddresse: 'www.zeiss.de',
      land: 'Deutschland',
      mobilnummer: '015745678942',
      ort: 'Jena',
      plz: '07743',
      steuernummer: '950/750/3005',
      strasse: 'Carl-Zeiss-Promenade',
      telefonnummer: '036416770',
      titel: 'Dr.',
      ustId: 'DE998877665'
    },
    '5': {
      id: '5',
      inhaberId: '2',
      kundennummer: 4567890123,
      anrede: 'Frau',
      nachname: 'Weber',
      vorname: 'Julia',
      firma: 'Julia Weber GmbH',
      bezeichnung: 'Julia Weber GmbH',
      emailAdresse: 'julia@weber.com',
      hausnummer: '24',
      internetaddresse: 'www.weber.com',
      land: 'Deutschland',
      mobilnummer: '017745679812',
      ort: 'Stuttgart',
      plz: '70174',
      steuernummer: '755/853/9924',
      strasse: 'Königstraße',
      telefonnummer: '07112345678',
      titel: 'M.Sc.',
      ustId: 'DE654321876'
    },
    '6': {
      id: '6',
      inhaberId: '2',
      kundennummer: 5678901234,
      anrede: 'Herr',
      nachname: 'Bauer',
      vorname: 'Friedrich',
      firma: 'Friedrich Bauer KG',
      bezeichnung: 'Friedrich Bauer KG',
      emailAdresse: 'friedrich@bauerk.de',
      hausnummer: '28',
      internetaddresse: 'www.bauerk.de',
      land: 'Deutschland',
      mobilnummer: '016212345678',
      ort: 'Stuttgart',
      plz: '70173',
      steuernummer: '265/593/5813',
      strasse: 'Hauptstraße',
      telefonnummer: '07111234567',
      titel: 'MBA',
      ustId: 'DE101212101'
    },
    '7': {
      id: '7',
      inhaberId: '2',
      kundennummer: 6789012345,
      anrede: 'Frau',
      nachname: 'Schultz',
      vorname: 'Lena',
      firma: 'Lena Schultz GmbH',
      bezeichnung: 'Lena Schultz GmbH',
      emailAdresse: 'lena@schultz.de',
      hausnummer: '32',
      internetaddresse: 'www.schultz.de',
      land: 'Deutschland',
      mobilnummer: '01749999999',
      ort: 'Berlin',
      plz: '10117',
      steuernummer: '801/501/25914',
      strasse: 'Alexanderplatz',
      telefonnummer: '0309876543',
      titel: 'M.A.',
      ustId: 'DE135792468'
    },
    '8': {
      id: '8',
      inhaberId: '2',
      kundennummer: 7890123456,
      anrede: 'Frau',
      nachname: 'Heinz',
      vorname: 'Isabella',
      firma: 'Isabella Heinz',
      bezeichnung: 'Isabella Heinz',
      emailAdresse: 'contact@heinz-cosmetics.com',
      hausnummer: '47',
      internetaddresse: 'www.heinz-cosmetics.com',
      land: 'Deutschland',
      mobilnummer: '01701010101',
      ort: 'Hamburg',
      plz: '20457',
      steuernummer: '869/748/95468',
      strasse: 'Neuer Wall',
      telefonnummer: '0401234567',
      titel: 'B.Sc.',
      ustId: 'DE201020304'
    },
    '9': {
      id: '9',
      inhaberId: '2',
      kundennummer: 8901234567,
      anrede: 'Herr',
      nachname: 'Kern',
      vorname: 'Otto',
      firma: 'Otto Kern',
      bezeichnung: 'Otto Kern',
      emailAdresse: 'otto@kern.de',
      hausnummer: '88',
      internetaddresse: 'www.kern.de',
      land: 'Deutschland',
      mobilnummer: '015201020304',
      ort: 'Köln',
      plz: '50667',
      steuernummer: '760/856/9087',
      strasse: 'Hohe Straße',
      telefonnummer: '02211234567',
      titel: 'Prof. Dr.',
      ustId: 'DE909876543'
    },
    '10': {
      id: '10',
      inhaberId: '2',
      kundennummer: 9012345678,
      anrede: 'Herr',
      nachname: 'Leitner',
      vorname: 'George',
      firma: 'George Leitner',
      bezeichnung: 'George Leitner',
      emailAdresse: 'george@leitner-modular.com',
      hausnummer: '29',
      internetaddresse: 'www.leitner-modular.com',
      land: 'Deutschland',
      mobilnummer: '01601234567',
      ort: 'Leipzig',
      plz: '04109',
      steuernummer: '690/777/55578',
      strasse: 'Augustusplatz',
      telefonnummer: '03411234567',
      titel: 'M.Eng.',
      ustId: 'DE101112131'
    },
    '11': {
      id: '11',
      inhaberId: '2',
      kundennummer: 1122334455,
      anrede: 'Frau',
      nachname: 'Curie',
      vorname: 'Marie',
      firma: 'Marie Curie',
      bezeichnung: 'Marie Curie',
      emailAdresse: 'marie@curielab.com',
      hausnummer: '12',
      internetaddresse: 'www.curielab.com',
      land: 'Frankreich',
      mobilnummer: '030123456789',
      ort: 'Paris',
      plz: '75001',
      steuernummer: '790/888/6664',
      strasse: 'Rue Lavoisier',
      telefonnummer: '0121234567',
      titel: 'Dr.',
      ustId: 'FR123456789'
    },
    '12': {
      id: '12',
      inhaberId: '2',
      kundennummer: 2233445566,
      anrede: 'Herr',
      nachname: 'Müller',
      vorname: 'Peter',
      firma: 'Peter Müller KG',
      bezeichnung: 'Peter Müller KG',
      emailAdresse: 'info@muellerkg.de',
      hausnummer: '85',
      internetaddresse: 'www.muellerkg.de',
      land: 'Deutschland',
      mobilnummer: '015025025025',
      ort: 'Dresden',
      plz: '01067',
      steuernummer: '490/490/00494',
      strasse: 'Prager Straße',
      telefonnummer: '0351203040',
      titel: 'Dr.',
      ustId: 'DE750951753'
    },
    '13': {
      id: '13',
      inhaberId: '2',
      kundennummer: 3344556677,
      anrede: 'Frau',
      nachname: 'Hermann',
      vorname: 'Elisa',
      firma: 'Elisa Hermann Solutions',
      bezeichnung: 'Elisa Hermann',
      emailAdresse: 'elisa@hermannsolutions.org',
      hausnummer: '75',
      internetaddresse: 'www.hermannsolutions.org',
      land: 'Deutschland',
      mobilnummer: '015790123456',
      ort: 'Dresden',
      plz: '01069',
      steuernummer: '245/999/2034',
      strasse: 'Wiener Platz',
      telefonnummer: '0351748392',
      titel: 'PhD',
      ustId: 'DE999888777'
    },
    '14': {
      id: '14',
      inhaberId: '2',
      kundennummer: 4455667788,
      anrede: 'Herr',
      nachname: 'Schwarz',
      vorname: 'Tobias',
      firma: 'Tobias Schwarz GmbH',
      bezeichnung: 'Tobias Schwarz GmbH',
      emailAdresse: 'info@schwarzgmbh.de',
      hausnummer: '45',
      internetaddresse: 'www.schwarzgmbh.de',
      land: 'Deutschland',
      mobilnummer: '017112233445',
      ort: 'Hamburg',
      plz: '20459',
      steuernummer: '214/081/3344',
      strasse: 'Monckebergstraße',
      telefonnummer: '0401234567',
      titel: 'Dipl.-Kfm.',
      ustId: 'DE123455432'
    },
    '15': {
      id: '15',
      inhaberId: '2',
      kundennummer: 5566778899,
      anrede: 'Frau',
      nachname: 'Neuhaus',
      vorname: 'Laura',
      firma: 'Laura Neuhaus AG',
      bezeichnung: 'Laura Neuhaus AG',
      emailAdresse: 'laura@neuhausag.de',
      hausnummer: '90',
      internetaddresse: 'www.neuhausag.de',
      land: 'Deutschland',
      mobilnummer: '017612345685',
      ort: 'Kiel',
      plz: '24103',
      steuernummer: '304/556/7842',
      strasse: 'Holstenstraße',
      telefonnummer: '0431123456',
      titel: 'Dr.',
      ustId: 'DE231456789'
    },
    '16': {
      id: '16',
      inhaberId: '2',
      kundennummer: 6677889900,
      anrede: 'Herr',
      nachname: 'Weiß',
      vorname: 'Michael',
      firma: 'Michael Weiß Zwirnerei',
      bezeichnung: 'Michael Weiß Zwirnerei',
      emailAdresse: 'm.weiss@zwirnereiweiss.de',
      hausnummer: '5',
      internetaddresse: 'www.zwirnereiweiss.de',
      land: 'Deutschland',
      mobilnummer: '017512345670',
      ort: 'Lübeck',
      plz: '23552',
      steuernummer: '223/554/3219',
      strasse: 'Breite Straße',
      telefonnummer: '0451123456',
      titel: 'Prof. Dr.',
      ustId: 'DE987654321'
    },
    '17': {
      id: '17',
      inhaberId: '2',
      kundennummer: 7788990011,
      anrede: 'Frau',
      nachname: 'Felder',
      vorname: 'Susanne',
      firma: 'Susanne Felder KG',
      bezeichnung: 'Susanne Felder KG',
      emailAdresse: 's.felder@felderkg.de',
      hausnummer: '77',
      internetaddresse: 'www.felderkg.de',
      land: 'Deutschland',
      mobilnummer: '017612345619',
      ort: 'Rostock',
      plz: '18055',
      steuernummer: '336/079/4336',
      strasse: 'Kröpeliner Straße',
      telefonnummer: '0381123456',
      titel: 'PhD',
      ustId: 'DE876543210'
    },
    '18': {
      id: '18',
      inhaberId: '2',
      kundennummer: 8899001122,
      anrede: 'Herr',
      nachname: 'Klein',
      vorname: 'Hans',
      firma: 'Hans Klein Media',
      bezeichnung: 'Hans Klein Media',
      emailAdresse: 'info@kleinmedia.de',
      hausnummer: '11',
      internetaddresse: 'www.kleinmedia.de',
      land: 'Deutschland',
      mobilnummer: '017632541478',
      ort: 'Leipzig',
      plz: '04109',
      steuernummer: '099/565/7893',
      strasse: 'Grimmaische Straße',
      telefonnummer: '03414681011',
      titel: 'Dr.',
      ustId: 'DE102938475'
    },
    '19': {
      id: '19',
      inhaberId: '2',
      kundennummer: 9900112233,
      anrede: 'Frau',
      nachname: 'Stein',
      vorname: 'Emma',
      firma: 'Emma Stein Bau GmbH',
      bezeichnung: 'Emma Stein Bau GmbH',
      emailAdresse: 'e.stein@steinbau.de',
      hausnummer: '91',
      internetaddresse: 'www.steinbau.de',
      land: 'Deutschland',
      mobilnummer: '017611223344',
      ort: 'Dortmund',
      plz: '44135',
      steuernummer: '750/030/1122',
      strasse: 'Kampstraße',
      telefonnummer: '02311234567',
      titel: 'Dipl.-Ing.',
      ustId: 'DE123456780'
    },
    '20': {
      id: '20',
      inhaberId: '2',
      kundennummer: 1122334455,
      anrede: 'Herr',
      nachname: 'Jung',
      vorname: 'Felix',
      firma: 'Felix Jung GmbH',
      bezeichnung: 'Felix Jung GmbH',
      emailAdresse: 'f.jung@junggmbh.de',
      hausnummer: '59',
      internetaddresse: 'www.junggmbh.de',
      land: 'Deutschland',
      mobilnummer: '017612345690',
      ort: 'Essen',
      plz: '45127',
      steuernummer: '123/456/78910',
      strasse: 'Limbecker Platz',
      telefonnummer: '0201123456',
      titel: 'Prof.',
      ustId: 'DE109876543'
    },
    '21': {
      id: '21',
      inhaberId: '2',
      kundennummer: 2233445566,
      anrede: 'Herr',
      nachname: 'Schwarz',
      vorname: 'Oliver',
      firma: 'Oliver Schwarz IT-Solutions',
      bezeichnung: 'Oliver Schwarz IT-Solutions',
      emailAdresse: 'o.schwarz@itsolutions.de',
      hausnummer: '32',
      internetaddresse: 'www.itsolutions.de',
      land: 'Deutschland',
      mobilnummer: '01761239988',
      ort: 'Bremen',
      plz: '28195',
      steuernummer: '132/465/7987',
      strasse: 'Hanseatenhof',
      telefonnummer: '0421123456',
      titel: 'MSc',
      ustId: 'DE2143658709'
    },
    '22': {
      id: '22',
      inhaberId: '2',
      kundennummer: 3344556677,
      anrede: 'Frau',
      nachname: 'Groß',
      vorname: 'Melanie',
      firma: 'Melanie Groß Textilien',
      bezeichnung: 'Melanie Groß Textilien',
      emailAdresse: 'contact@grosstext.de',
      hausnummer: '46',
      internetaddresse: 'www.grosstext.de',
      land: 'Deutschland',
      mobilnummer: '017640120987',
      ort: 'Wiesbaden',
      plz: '65183',
      steuernummer: '208/276/0538',
      strasse: 'Kirchgasse',
      telefonnummer: '0611123456',
      titel: 'Dipl.-Bw.',
      ustId: 'DE987654320'
    },
    '23': {
      id: '23',
      inhaberId: '2',
      kundennummer: 4455667788,
      anrede: 'Herr',
      nachname: 'Vogel',
      vorname: 'Andreas',
      firma: 'Andreas Vogel Consulting',
      bezeichnung: 'Andreas Vogel Consulting',
      emailAdresse: 'a.vogel@vogelconsulting.de',
      hausnummer: '18',
      internetaddresse: 'www.vogelconsulting.de',
      land: 'Deutschland',
      mobilnummer: '017612345601',
      ort: 'Heidelberg',
      plz: '69117',
      steuernummer: '360/746/2880',
      strasse: 'Hauptstraße',
      telefonnummer: '06221123456',
      titel: 'Dr.',
      ustId: 'DE192837465'
    },
    '24': {
      id: '24',
      inhaberId: '2',
      kundennummer: 5566778899,
      anrede: 'Frau',
      nachname: 'Sonne',
      vorname: 'Victoria',
      firma: 'Victoria Sonne GmbH',
      bezeichnung: 'Victoria Sonne GmbH',
      emailAdresse: 'v.sonne@sonnegmbh.de',
      hausnummer: '22',
      internetaddresse: 'www.sonnegmbh.de',
      land: 'Deutschooand',
      mobilnummer: '017644412212',
      ort: 'Freiburg',
      plz: '79098',
      steuernummer: '220/990/4034',
      strasse: 'Kaiser-Joseph-Straße',
      telefonnummer: '0761123456',
      titel: 'PhD',
      ustId: 'DE198765432'
    },
    '25': {
      id: '25',
      inhaberId: '2',
      kundennummer: 6677889900,
      anrede: 'Herr',
      nachname: 'Meyer',
      vorname: 'Frederik',
      firma: 'Frederik Meyer Logistik',
      bezeichnung: 'Frederik Meyer Logistik',
      emailAdresse: 'f.meyer@meyerlogistik.de',
      hausnummer: '39',
      internetaddresse: 'www.meyerlogistik.de',
      land: 'Deutschland',
      mobilnummer: '017677889900',
      ort: 'Nürnberg',
      plz: '90402',
      steuernummer: '241/674/5543',
      strasse: 'Karolinenstraße',
      telefonnummer: '0911123456',
      titel: 'Dr.',
      ustId: 'DE208765431'
    },
    '26': {
      id: '26',
      inhaberId: '2',
      kundennummer: 7788990011,
      anrede: 'Frau',
      nachname: 'Neumann',
      vorname: 'Isabelle',
      firma: 'Isabelle Neumann New Media',
      bezeichnung: 'Isabelle Neumann New Media',
      emailAdresse: 'isabelle@neumannmedia.de',
      hausnummer: '21',
      internetaddresse: 'www.neumannmedia.de',
      land: 'Deutschland',
      mobilnummer: '017699887766',
      ort: 'München',
      plz: '80333',
      steuernummer: '342/766/4880',
      strasse: 'Maximilianstraße',
      telefonnummer: '0891234567',
      titel: 'M.A.',
      ustId: 'DE123456788'
    },
    '27': {
      id: '27',
      inhaberId: '2',
      kundennummer: 8899001122,
      anrede: 'Herr',
      nachname: 'Sommer',
      vorname: 'Theo',
      firma: 'Theo Sommer Elektronik GmbH',
      bezeichnung: 'Theo Sommer Elektronik GmbH',
      emailAdresse: 'theo@sommer-elektronik.de',
      hausnummer: '67',
      internetaddresse: 'www.sommer-elektronik.de',
      land: 'Deutschland',
      mobilnummer: '017655544332',
      ort: 'Düsseldorf',
      plz: '40215',
      steuernummer: '150/750/4590',
      strasse: 'Friedrichstraße',
      telefonnummer: '02111234678',
      titel: 'Dipl.-Ing.',
      ustId: 'DE304957286'
    },
    '28': {
      id: '28',
      inhaberId: '2',
      kundennummer: 9900112233,
      anrede: 'Frau',
      nachname: 'Dietrich',
      vorname: 'Helena',
      firma: 'Helena Dietrich Design',
      bezeichnung: 'Helena Dietrich Design',
      emailAdresse: 'info@dietrichdesign.de',
      hausnummer: '23',
      internetaddresse: 'www.dietrichdesign.de',
      land: 'Deutschland',
      mobilnummer: '017688855544',
      ort: 'Hannover',
      plz: '30159',
      steuernummer: '301/483/9098',
      strasse: 'Georgstraße',
      telefonnummer: '05111234567',
      titel: 'M.F.A.',
      ustId: 'DE987654321'
    },
    '29': {
      id: '29',
      inhaberId: '2',
      kundennummer: 1122334455,
      anrede: 'Frau',
      nachname: 'Lorenz',
      vorname: 'Sophia',
      firma: 'Sophia Lorenz AG',
      bezeichnung: 'Sophia Lorenz AG',
      emailAdresse: 'sophia@lorenz.de',
      hausnummer: '2',
      internetaddresse: 'www.lorenz.de',
      land: 'Deutschland',
      mobilnummer: '01761239876',
      ort: 'Düsseldorf',
      plz: '40213',
      steuernummer: '987/654/3210',
      strasse: 'Königsallee',
      telefonnummer: '02111234567',
      titel: 'MBA',
      ustId: 'DE876543219'
    },
    '30': {
      id: '30',
      inhaberId: '2',
      kundennummer: 2233445566,
      anrede: 'Herr',
      nachname: 'Nowak',
      vorname: 'Jan',
      firma: 'Jan Nowak',
      bezeichnung: 'Jan Nowak',
      emailAdresse: 'jan@nowak.pl',
      hausnummer: '18',
      internetaddresse: 'www.nowak.pl',
      land: 'Polen',
      mobilnummer: '048612345678',
      ort: 'Warschau',
      plz: '00-001',
      steuernummer: '143/555/7894',
      strasse: 'Miodowa',
      telefonnummer: '0221234567',
      titel: 'PhD',
      ustId: 'PL123456789'
    },
  },
  ids: [
    '1', '2', '3', '4', '5', '6', '7', '8', '9', '10',
    '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
    '21', '22', '23', '24', '25', '26', '27', '28', '29', '30',
    '29', '28', '27', '26', '25', '24', '23', '22', '21', '20',
    '19', '18', '17', '16', '15', '14', '13', '12', '11', '10',
    '9', '8', '7', '6', '5', '4', '3', '2', '1',
  ]
};
