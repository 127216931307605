import {createReducer, on} from '@ngrx/store';
import {ProduktEntitiesState} from '../states/produkt-entities.state';
import {produktEntitiesAdapter} from '../adapters/produkt-entities.adapter';
import {produktEntitiesMock} from './produkt-entities.mock';
import {ProduktEntitiesActions} from '../actions/produkt-entities.actions';


export const initialProduktEntitiesState: ProduktEntitiesState = produktEntitiesAdapter.getInitialState({
  isLoading: false,
  entities: produktEntitiesMock.entities,
  ids: produktEntitiesMock.ids,
  count: produktEntitiesMock.ids.length,
  nextProduktnummer: 3, // FIXME: mock entfernen
});

export const produktEntitiesReducer = createReducer(
  initialProduktEntitiesState,

  on(
    ProduktEntitiesActions.readNextProduktnummerSuccess,
    (state, action) => ({
      ...state,
      nextProduktnummer: action.produktnummer,
    })
  )
);

