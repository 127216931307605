import {absenderEntitiesAdapter} from '../adapters/absender-entities.adapter';
import {createSelector, MemoizedSelector} from '@ngrx/store';
import {AppState} from '../states/app.state';
import {AbsenderDTO} from '../../openapi/fakturierung-openapi';
import {identity} from 'rxjs';


export class AbsenderEntitiesSelectors {

  private static adapterSelectors = absenderEntitiesAdapter.getSelectors();

  /**
   * Die `select`-Eigenschaft ist ein Objekt, das Funktionen definiert, jede Funktion selektiert eine
   * bestimmte Eigenschaft aus dem State.
   * Das sind grundlegende Selektoren, die im ganzen Projekt als Bausteine für komplexere Selektoren wiederverwendet
   * werden können.
   */
  private static select = {
    absenderEntities: (state: AppState) => state.absenderEntities,
    count: (state: AppState) => state.absenderEntities.count,
  };

  public static all = createSelector(
    this.select.absenderEntities,
    this.adapterSelectors.selectAll,
  );

  public static absenderById = (absenderId: string) => createSelector(
    this.all,
    absenderList => absenderList.find(absender => {
      return absender.id === absenderId;
    }),
  );

  /**
   * Selektor, der Absender anhand einer Liste von IDs zurückgibt und dabei deren Reihenfolge beibehält.
   *
   * @param {string[]} absenderIds - Ein Array von BelegIDs.
   * Die Reihenfolge dieses Arrays bestimmt die Reihenfolge der zurückgegebenen Absender.
   *
   * @returns {MemoizedSelector<AppState, AbsenderDTO[]>} Ein Selektor, der ein Array von Belegen zurückgibt, die den
   * angegebenen IDs entsprechen, in der in `absenderIds` angegebenen Reihenfolge für den ausgewählten Inhaber.
   * Gibt ein leeres Array zurück, wenn keine übereinstimmenden Absender gefunden wurden.
   */
  public static absenderByIds = (absenderIds: string[]): MemoizedSelector<AppState, AbsenderDTO[]> => createSelector(
    this.all,
    absenderDtos => {
      const absenderMap = new Map(absenderDtos.map(absenderDto => [absenderDto.id, absenderDto]));
      return absenderIds
        .map(id => absenderMap.get(id))
        .filter((absenderDto): absenderDto is AbsenderDTO => !!absenderDto);
    }
  );

  public static absenderByInhaberId = (inhaberId: string) => createSelector(
    this.all,
    absenderList => absenderList.filter(absender => absender && absender.inhaberId === inhaberId),
  );

  public static count = createSelector(
    this.select.count,
    identity,
  );

}
