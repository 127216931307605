import {AppState} from '../states/app.state';
import {identity} from 'rxjs';
import {createSelector} from '@ngrx/store';


export class DeleteFakturierungsbelegDialogSelectors {

  /**
   * Die `select`-Eigenschaft ist ein Objekt, das Funktionen definiert, jede Funktion selektiert eine
   * bestimmte Eigenschaft aus dem State.
   * Das sind grundlegende Selektoren, die im ganzen Projekt als Bausteine für komplexere Selektoren wiederverwendet
   * werden können.
   */
  private static select = {
    isOpen: (state: AppState) => state.deleteFakturierungsbelegDialog.isOpen,
    inhaberId: (state: AppState) => state.deleteFakturierungsbelegDialog.inhaberId,
    fakturierungbelegId: (state: AppState) => state.deleteFakturierungsbelegDialog.fakturierungbelegId,
  };

  public static isDeleteFakturierungsbelegDialogOpen = createSelector(
    this.select.isOpen,
    identity,
  );

  public static deleteData = createSelector(
    this.select.inhaberId,
    this.select.fakturierungbelegId,
    (inhaberId, fakturierungbelegId) => {
      return {
        inhaberId,
        fakturierungbelegId,
      };
    }
  );
}
