import {Betrieb, NavigationService} from '@adnova/jf-ng-components';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {combineLatest, Subject, takeUntil} from 'rxjs';
import {RouterSelectors} from '../../../store/selectors/router.selectors';
import {AppState} from '../../../store/states/app.state';


@Component({
  selector: 'app-betriebsauswahl-page',
  templateUrl: './betriebsauswahl.page.component.html'
})
export class BetriebsauswahlPageComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$ = new Subject<void>();
  protected betriebList: Betrieb[] = [];
  private readonly regex = /\/inhaber\/([^\/\?]+)/; //INFO: entspricht Inhaber/:inhaberId aus der URL

  constructor(
    private readonly navigationService: NavigationService,
    private readonly store: Store<AppState>,
  ) {
  }

  /** INFO:
   * sowohl der Navigationservice, als auch der Store kommen aus der Library.
   * Somit kann das setzen der Inhaberlist, als auch das setzen der Url in die Library ausgelagert werden.
   *
   * Weiteres TODO: CurrentInhaber ist das im Lib Store und geben wir das immer mit? sollten wir, damit
   * das nicht in den Routerlinks gemacht werden muss.
   */

  ngOnInit(): void {
    combineLatest([
      this.navigationService.inhaberList$,
      this.store.select(RouterSelectors.url),
      //Queryparams
    ]).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(([inhaberList, url]) => {
      this.betriebList = inhaberList.map(inhaber => {
        return {
          id: inhaber.id,
          betriebsnummer: inhaber.betriebsnummer,
          nummer: inhaber.nummer,
          geschaeftstaetigkeit: inhaber.geschaeftstaetigkeit || '',
          bezeichnung: inhaber.bezeichnung,
          url: this.setUrl(inhaber.id, url),
          //  queryParam: params,
        };
      });
    });
  }

  private setUrl(inhaber: string, url?: string): string {
    if (url?.match(this.regex)) {
      return url.replace(this.regex, '/inhaber/' + inhaber);
    } else {
      return url + '/inhaber/' + inhaber;
      //INFO: url ist inclusive queryParameter, d.H dieser wird bei Inhaberwechsel mitgegeben.
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
