<section class="filter-bar">
  <div class="visible-row">
    <div class="visible-row-filter">
      <ng-content select="[visibleRow]"></ng-content>
    </div>

    <!--  INFO: Button einblenden, wenn weitere Filter vorhanden sind  -->
    <!--    <jf-button variant="ghost" icon="filter">Filter {{filterVisible}}</jf-button>-->
  </div>
  <div class="hidden-row">
    <ng-content select="[hiddenRow]"></ng-content>
  </div>
</section>
