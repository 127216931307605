import {
  BerechnungsartDTO,
  BerechnungsartDTOBerechnungsartEnum,
  EinheitDTO,
  EinheitDTOMengenartEnum,
  UstProzentsatzDTO
} from '../../openapi/fakturierung-openapi';


export const produktEinheitenMock: EinheitDTO[] = [
  {
    einheitId: '1',
    nummer: 11,
    kurzbezeichnung: 'Stk.',
    bezeichnung: 'Stück',
    mengenart: EinheitDTOMengenartEnum.Stueckmenge,
  },
  {
    einheitId: '2',
    nummer: 12,
    kurzbezeichnung: 'kg',
    bezeichnung: 'Kilogramm',
    mengenart: EinheitDTOMengenartEnum.Gewichtsmenge,
  },
  {
    einheitId: '3',
    nummer: 13,
    kurzbezeichnung: 'l',
    bezeichnung: 'Liter',
    mengenart: EinheitDTOMengenartEnum.Gewichtsmenge,
  },
  {
    einheitId: '4',
    nummer: 14,
    kurzbezeichnung: 'sqrm',
    bezeichnung: 'Quadratmeter',
    mengenart: EinheitDTOMengenartEnum.Stueckmenge,
  },
  {
    einheitId: '5',
    nummer: 15,
    kurzbezeichnung: 'm',
    bezeichnung: 'Meter',
    mengenart: EinheitDTOMengenartEnum.Stueckmenge,
  }
];

export const produktUstSaetzeMock: UstProzentsatzDTO[] = [
  {
    id: '0',
    prozentsatz: 19,
  },
  {
    id: '1',
    prozentsatz: 7,
  },
  {
    id: '2',
    prozentsatz: 9,
  },
  {
    id: '3',
    prozentsatz: 5.5,
  },
  {
    id: '4',
    prozentsatz: 0,
  }
];

export const produktBerechnungsartenMock: BerechnungsartDTO[] = [
  {
    id: 'Netto',
    berechnungsart: BerechnungsartDTOBerechnungsartEnum.Netto,
  },
  {
    id: 'Brutto',
    berechnungsart: BerechnungsartDTOBerechnungsartEnum.Brutto,
  },
];
