import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TableRowButton} from './table-row-button.interface';


@Component({
  selector: 'app-table-row-buttons',
  templateUrl: './table-row-buttons.component.html',
  styleUrls: ['./table-row-buttons.component.scss']
})
export class TableRowButtonsComponent {

  @Input() primaryButton?: TableRowButton;

  @Input() secondaryButton?: TableRowButton;

  @Input() tertiaryButton?: TableRowButton;

  @Input() moreOptionPrimaryButtons: TableRowButton[] = [];

  @Input() moreOptionSecondaryButtons: TableRowButton[] = [];

  @Input() moreOptionTertiaryButtons: TableRowButton[] = [];

  @Input() showMoreOptionButtons = false;

  @Output() buttonIdClick = new EventEmitter<string>();

  protected buttonClick(id: string) {
    this.buttonIdClick.emit(id);
  }
}
