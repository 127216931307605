<div class="dialog-wrapper">

  <h1
    class="dialog-h1"
    i18n
  >
    Rechnung löschen
  </h1>

  <div
    class="beleg-data"
    i18n
  >
    <div *ngIf="fakturierungsbelegDto?.kundenbezeichnung">{{ fakturierungsbelegDto?.kundenbezeichnung }}</div>
    <div *ngIf="fakturierungsbelegDto?.kundennummer">Kundennummer: {{ fakturierungsbelegDto?.kundennummer }}</div>
    <div *ngIf="fakturierungsbelegDto?.fakturierungsbelegnummer">Rechnungsnummer: {{ fakturierungsbelegDto?.fakturierungsbelegnummer }}</div>
    <div *ngIf="fakturierungsbelegDto?.nettoBetragssumme">{{ fakturierungsbelegDto?.nettoBetragssumme | currency: 'EUR' }} Netto</div>
  </div>

  <mat-divider></mat-divider>

  <div class="button-wrapper">

    <button
      class="delete-button"
      mat-raised-button
      i18n
      (click)="deleteFakturierungsbelegClick()"
    >
      Löschen
    </button>

    <button
      mat-flat-button
      i18n
      (click)="closeDialogClick()"
    >
      Abbrechen
    </button>
  </div>
</div>
