import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TableRowButtonsComponent} from './table-row-buttons.component';
import {MatMenuModule} from '@angular/material/menu';
import {IconModule, TooltipModule} from '@adnova/jf-ng-components';
import {MatListModule} from '@angular/material/list';


@NgModule({
  declarations: [
    TableRowButtonsComponent,
  ],
  exports: [
    TableRowButtonsComponent,
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    IconModule,
    TooltipModule,
    MatListModule,
  ]
})
export class TableRowButtonsModule {
}
