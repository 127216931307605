import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {from} from 'rxjs';
import {PortalMessageBroker} from '../portal/portal-message-broker';


export interface ExampleDataV1 {
  readonly exampleId: string;
}

@Injectable({
  providedIn: 'root'
})
export class IntentActionService {

  private readonly exampleV1 = 'de.just-farming:example:example';

  constructor(
    private logger: NGXLogger,
    private portalMessageBroker: PortalMessageBroker,
  ) {
    if (this.isRunningInPortal()) {
      this.portalMessageBroker.registerIntentCallback(this.exampleV1, '1', (data => {
        this.handleExampleV1(data);
      }));

      this.portalMessageBroker.allIntentCallbackRegistered();
    }
  }

  /**
   * Prüft ob die App im Portal läuft
   */
  public isRunningInPortal(): boolean {
    return this.portalMessageBroker.isRunningInPortal();
  }

  public doExampleV1(
    data: ExampleDataV1,
  ): void {
    this.doEmit(this.exampleV1, '1', data);
  }

  handleExampleV1(
    data: ExampleDataV1
  ): void {
    // TODO impl
  }

  private doEmit(
    intentId: string,
    intentVersion: string,
    data: any,
  ): void {
    if (!this.isRunningInPortal()) {
      throw new Error('app is not running in portal');
    }

    const promise = this.portalMessageBroker.emitIntent(intentId, intentVersion, data);
    from(promise)
      .subscribe(
        value => {
          this.logger.debug('IntentActionService.doEmit(): id=' + intentId + ' version=' + intentVersion + ' handles successful', value);
        },
        error => {
          this.logger.debug('IntentActionService.doEmit(): id=' + intentId + ' version=' + intentVersion + ' dispatch failed', error);
        },
      );
  }
}
