<ng-container *ngIf="!emptyState">
  <app-filter-bar>
    <jf-form-field-text
      visibleRow
      [formControl]="formControl"
      [label]="'Suche'"
      [iconName]="'search'"
    >
    </jf-form-field-text>

  </app-filter-bar>

  <app-table-wrapper
    [tableSettingsFallback]="tableSettingsFallback"
    [tableSettings]="tableSettings"
    [displayedItems]="displayedItems"
    [tableCells]="tableCells"
    [useMultisort]="false"
    (changedTableSettings)="changedTableSettingsHandler($event)"
  >
  </app-table-wrapper>

  <app-pagination
    [count]="count"
    [offset]="offset"
    [limit]="limit"
    (pageableChange)="changedPageableHandler($event)"
  ></app-pagination>
</ng-container>

<app-empty-state
  *ngIf="emptyState"
  headline="Noch keine Rechnungen?"
  illustration="assets/images/EmptyState-Faktura--KeineRechnungen.png"
>
  <div content>
    <p>Erstelle und versende deine erste <b>E-Rechnung</b> direkt hier - einfach und effizient. Entdecke wie du Zeit
      sparen und deine Finanzen besser organisieren kannst.</p>
    <button (click)="openBelegeEmptyStateDialog()">Mehr erfahren</button>
  </div>
</app-empty-state>

