import {createReducer, on} from '@ngrx/store';
import {AbsenderColumn, AbsenderTableState} from '../states/absender-table.state';
import {AbsenderTableActions} from '../actions/absender-table.actions';
import {absenderEntitiesMock} from './absender-entities.mock';
import {PageableAbsenderDTOSortingEnum} from '../../openapi/fakturierung-openapi';


export const initialAbsenderTableState: AbsenderTableState = {
  // FIXME: Passende Diplayed-Ids laden
  displayedIds: absenderEntitiesMock.ids,
  tableSettings: {
    _key: 'absender-table-settings-2024-06-18',
    _columns: [
      {
        id: AbsenderColumn.NameVorname,
        name: 'Nachname, Vorname',
        isActive: true,
        width: 15,
        overflow: 'ellipsis',
      }, {
        id: AbsenderColumn.Firma,
        name: 'Firma',
        isActive: true,
        width: 15,
        overflow: 'break',
      }, {
        id: AbsenderColumn.Adresse,
        name: 'Ort',
        isActive: true,
        width: 10,
        overflow: 'break',
      }, {
        id: AbsenderColumn.Actions,
        name: '',
        isActive: true,
        sortable: false,
        lastCol: true,
      },
    ],
    _sortDirs: [],
    _sortParams: [],
  },
  pageable: {
    limit: 50,
    offset: 0,
    sorting: [PageableAbsenderDTOSortingEnum.Erstelltasc],
  },
  filter: {
    textfilter: [''],
  }
};

export const absenderTableReducer = createReducer(
  initialAbsenderTableState,

  on(
    AbsenderTableActions.changeTableSettings,
    (state, action) => ({
      ...state,
      tableSettings: action.tableSettings,
    })
  ),

  on(
    AbsenderTableActions.changePage,
    (state, action) => ({
      ...state,
      pageable: {
        ...state.pageable,
        ...action.pageable,
      },
    })
  ),

  on (
      AbsenderTableActions.updateSearchValue,
      (state, action) => ({
        ...state,
        filter: {
          textfilter: action.searchValue,
        }
      })
  ),

);
