import {TableSettings} from '../../interfaces/table-setting.interface';
import {FilterProduktDTO, PageableProduktDTO} from '../../openapi/fakturierung-openapi';


export enum ProduktColumn {
  Nummer = 'nummer',
  Bezeichnung = 'produkt',
  Preis = 'preis',
  USt = 'ust',
  Actions = 'actions',
}

export interface ProduktTableState {
  displayedIds: string[];
  tableSettings: TableSettings;
  pageable: PageableProduktDTO;
  filter: FilterProduktDTO;
}
