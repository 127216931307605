import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TableSettings} from '../../../../interfaces/table-setting.interface';
import {TableCells} from './table-cells.type';


@Component({
  selector: 'app-table-wrapper',
  templateUrl: './table-wrapper.component.html',
  styleUrls: ['./table-wrapper.component.scss'],
})
export class TableWrapperComponent<TableItemDTO> {

  @Input()
  tableSettingsFallback?: TableSettings;

  @Input()
  tableSettings?: TableSettings;

  /**
   * Flag, der angibt, ob es sich um eine Multi- oder Singlesort Tabelle handelt. Multisort-Tabellen können mehrere
   * Sortierebenen erhalten.
   */
  @Input()
  useMultisort: boolean = false;

  /**
   * Die angezeigten Entitäten aus dem Store.
   */
  @Input()
  displayedItems: TableItemDTO[] = [];

  /**
   * Die Zellen-Komponenten, die in die einzelnen Zellen pro Spalte gerendert werden. Die Komponenten werden pro
   * Tabelleninstanz nach folgendem Muster angelegt:
   * ```
   * @Component({
   *   standalone: true,
   *   template: '{{data?.exampleData}}',
   * })
   * export class ExampleCellComponent {
   *   constructor(@Inject(ELEMENT_DATA) public data: ExampleDto) {
   * }
   * ```
   */
  @Input()
  tableCells?: TableCells;

  @Output()
  changedTableSettings = new EventEmitter<TableSettings>();

  changedTableSettingsHandler(tableSettings: TableSettings): void {
    this.changedTableSettings.emit(tableSettings);
  }

}
