import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BasePageableDTO} from '../../../openapi/fakturierung-openapi';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {

  @Input()
  set count(count: number) {
    this._count = count;
  }

  @Input()
  set offset(offset: number) {
    this._offset = offset;
  }

  @Input()
  set limit(limit: number) {
    this._limit = limit;
  }

  @Output()
  pageableChange = new EventEmitter<BasePageableDTO>();

  private _count: number = 0;
  private _offset: number = 0;
  private _limit: number = 0;

  get count(): number {
    return this._count;
  }

  get offsetStart(): number {
    return this._offset + 1;
  }

  get offsetEnd(): number {
    return this._offset + this._limit;
  }

  paginateFirst(): void {
    this._offset = 0;
    this.emitPageableChange();
  }

  paginatePrevious(): void {
    this._offset = Math.max(this._offset - this._limit, 0);
    this.emitPageableChange();
  }

  paginateNext(): void {
    this._offset = Math.min(this._offset + this._limit, this._count - 1);
    this.emitPageableChange();
  }

  paginateLast(): void {
    this._offset = Math.floor((this._count - 1) / this._limit) * this._limit;
    this.emitPageableChange();
  }

  isFirstPage(): boolean {
    return this._offset === 0;
  }

  isLastPage(): boolean {
    return this._offset + this._limit >= this._count;
  }

  private emitPageableChange(): void {
    this.pageableChange.emit({
      offset: this._offset,
      limit: this._limit,
    });
  }

}
