import {Component, inject} from '@angular/core';
import {ELEMENT_DATA} from '../../../../components/table/table-wrapper/table/table.component';
import {IconModule} from '@adnova/jf-ng-components';


@Component({
  standalone: true,
  selector: 'app-status.cell',
  templateUrl: './status.cell.component.html',
  styleUrls: ['./status.cell.component.scss'],
  imports: [
    IconModule,
  ],
})
export class StatusCellComponent {

  /*
   * TODO: Type "any" muss auf FakturierungsbelegDTO geändert werden. Aktuell gibt es aber noch abweichungen zwischen
   * der DTO-Definition in der OpenApi und der Implementierung, sodass der Type aktuell noch nicht korrekt gesetzt
   * werden kann.
   */
  protected data: any = inject(ELEMENT_DATA);

}
