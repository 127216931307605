<!-- INFO: Primär Button -->
<button
  *ngIf="primaryButton !== undefined"
  class="table-row-button"
  [jf-tooltip]="primaryButton.tooltip"
  [position]="{vertical: 'top', horizontal: 'center'}"
  [attr.aria-label]="primaryButton.tooltip"
  (click)="buttonClick(primaryButton.id)"
>
  <jf-icon
    [size]="20"
    [name]="primaryButton.iconName">
  </jf-icon>
</button>

<!-- INFO: Sekundär Button -->
<button
  *ngIf="secondaryButton !== undefined"
  class="table-row-button"
  [jf-tooltip]="secondaryButton.tooltip"
  [position]="{vertical: 'bottom', horizontal: 'center'}"
  [attr.aria-label]="secondaryButton.tooltip"
  (click)="buttonClick(secondaryButton.id)"
>
  <jf-icon
    [size]="20"
    [name]="secondaryButton.iconName">
  </jf-icon>
</button>

<!-- INFO: Tertiär Button -->
<button
  *ngIf="tertiaryButton !== undefined && !showMoreOptionButtons"
  class="table-row-button"
  [jf-tooltip]="tertiaryButton.tooltip"
  [position]="{vertical: 'bottom', horizontal: 'center'}"
  [attr.aria-label]="tertiaryButton.tooltip"
  (click)="buttonClick(tertiaryButton.id)"
>
  <jf-icon
    [size]="20"
    [name]="tertiaryButton.iconName">
  </jf-icon>
</button>

<!-- INFO: 'Mehr' Button -->
<button
  *ngIf="showMoreOptionButtons"
  class="table-row-button"
  [matMenuTriggerFor]="moreMenu"
>

  <jf-icon
    [size]="16"
    name="more">
  </jf-icon>

  <mat-menu #moreMenu="matMenu">

    <!-- Primäre 'Mehr' Buttons -->
    <button
      mat-menu-item
      class="more-menu-button"
      *ngFor="let button of moreOptionPrimaryButtons"
      [attr.aria-label]="button.tooltip"
      (click)="buttonClick(button.id)"
    >
      <span>{{ button.label }}</span>
    </button>

    <!-- Sekundäre 'Mehr' Buttons -->
    <ng-container *ngIf="moreOptionSecondaryButtons.length > 0">

      <mat-divider></mat-divider>

      <button
        mat-menu-item
        class="more-menu-button"
        *ngFor="let button of moreOptionSecondaryButtons"
        [attr.aria-label]="button.tooltip"
        (click)="buttonClick(button.id)"
      >
        <span>{{ button.label }}</span>
      </button>
    </ng-container>

    <!-- Tertiäre 'Mehr' Buttons -->
    <ng-container *ngIf="moreOptionTertiaryButtons.length > 0">

      <mat-divider></mat-divider>

      <button
        mat-menu-item
        class="more-menu-button"
        *ngFor="let button of moreOptionTertiaryButtons"
        [attr.aria-label]="button.tooltip"
        (click)="buttonClick(button.id)"
      >
        <span>{{ button.label }}</span>
      </button>
    </ng-container>
  </mat-menu>
</button>
