import {createAction, props} from '@ngrx/store';


export class DeleteProduktDialogActions {

    public static open = createAction(
      '[Produkt Table] Open delete produkt dialog.',
      props<{
        produktId: string;
      }>(),
    );

    public static close = createAction(
      '[Delete Produkt Dialog] Close delete produkt dialog.',
    );
}
