<!-- TODO: Sobald verfügbar: Icon ersetzen durch "paginationLeft" (klein geschrieben) -->
<jf-button
  jf-tooltip="Erste Seite"
  ariaLabel="Erste Seite"
  [position]="{vertical: 'top', horizontal: 'center'}"
  iconPosition="icon-only"
  icon="PaginationLeft"
  variant="ghost"
  [disabled]="isFirstPage()"
  (doClick)="paginateFirst()"
></jf-button>

<jf-button
  jf-tooltip="Vorherige Seite"
  ariaLabel="Vorherige Seite"
  [position]="{vertical: 'top', horizontal: 'center'}"
  iconPosition="icon-only"
  icon="expandLeft"
  variant="ghost"
  [disabled]="isFirstPage()"
  (doClick)="paginatePrevious()"
></jf-button>

<p>{{offsetStart}}-{{count < offsetEnd ? count : offsetEnd}} von {{count}}</p>

<jf-button
  jf-tooltip="Nächste Seite"
  ariaLabel="Nächste Seite"
  [position]="{vertical: 'top', horizontal: 'center'}"
  iconPosition="icon-only"
  icon="expandRight"
  variant="ghost"
  [disabled]="isLastPage()"
  (doClick)="paginateNext()"
></jf-button>

<jf-button
  jf-tooltip="Letzte Seite"
  ariaLabel="Letzte Seite"
  [position]="{vertical: 'top', horizontal: 'center'}"
  iconPosition="icon-only"
  icon="paginationRight"
  variant="ghost"
  [disabled]="isLastPage()"
  (doClick)="paginateLast()"
></jf-button>
