import {BelegeEmptyStateDialogActions} from '../actions/belege-empty-state-dialog.actions';
import {BelegeEmptyStateDialogState} from '../states/belege-empty-state-dialog.state';
import {createReducer, on} from '@ngrx/store';


export const initialBelegeEmptyStateDialogState: BelegeEmptyStateDialogState = {
  isOpen: false,
};

export const BelegeEmptyStateDialogReducer = createReducer(
  initialBelegeEmptyStateDialogState,

  on(BelegeEmptyStateDialogActions.open,
    (state, action) => ({
      ...state,
      isOpen: true,
    })
  ),

  on(BelegeEmptyStateDialogActions.close,
    () => ({
      ...initialBelegeEmptyStateDialogState,
    })
  ),
)
