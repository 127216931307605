import {createAction, props} from '@ngrx/store';
import {MappedHttpErrorResponse} from '../../interfaces/mapped-http-error-response.interface';


export class FakturierungsbelegEntitiesActions {

  public static deleteFakturierungsbeleg = createAction(
    '[Fakturierungsbeleg Table] Delete single fakturierungsbeleg.',
    props<{
      inhaberId: string,
      fakturierungsbelegId: string,
    }>(),
  );

  public static deleteFakturierungsbelegSuccess = createAction(
    '[Fakturierungsbeleg Entities] Delete single fakturierungsbeleg successfully.',
    props<{
      fakturierungsbelegId: string,
    }>(),
  );

  public static deleteFakturierungsbelegFailure = createAction(
    '[Fakturierungsbeleg Entities] Delete single fakturierungsbeleg failed.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

}
