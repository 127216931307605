import {Component, Input} from '@angular/core';


@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss']
})
export class EmptyStateComponent {

  private _headline = '';

  private _illustration = '';

  @Input()
  set headline(value: string) {
    this._headline = value;
  }

  get headline(): string {
    return this._headline;
  }

  @Input()
  set illustration(value: string) {
    this._illustration = value;
  }

  get illustration(): string {
    return this._illustration;
  }

}
