import {AbsenderEntitiesState} from '../states/absender-entities.state';
import {absenderEntitiesAdapter} from '../adapters/absender-entities.adapter';
import {createReducer} from '@ngrx/store';
import {absenderEntitiesMock} from './absender-entities.mock';


export const initialAbsenderEntitiesState: AbsenderEntitiesState = absenderEntitiesAdapter.getInitialState({
  isLoading: false,
  entities: absenderEntitiesMock.entities,
  ids: absenderEntitiesMock.ids,
  count: absenderEntitiesMock.ids.length,
});

export const absenderEntitiesReducer = createReducer(
  initialAbsenderEntitiesState,
);
