import {createAction, props} from '@ngrx/store';


export class DeleteAbsenderDialogActions {

    public static open = createAction(
      '[Absender Table] Open delete absender dialog.',
      props<{
        absenderId: string;
      }>(),
    );

    public static close = createAction(
      '[Delete Absender Dialog] Close delete absender dialog.',
    );
}
