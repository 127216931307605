import {DeleteProduktDialogState} from '../states/delete-produkt-dialog.state';
import {createReducer, on} from '@ngrx/store';
import {DeleteProduktDialogActions} from '../actions/delete-produkt-dialog.actions';


export const initialDeleteProduktDialogState: DeleteProduktDialogState = {
  isOpen: false,
};

export const deleteProduktDialogReducer = createReducer(
  initialDeleteProduktDialogState,

  on(
    DeleteProduktDialogActions.open,
    (state, action) => ({
      ...state,
      isOpen: true,
      produktId: action.produktId,
    })
  ),

  on(
    DeleteProduktDialogActions.close,
    () => ({
      ...initialDeleteProduktDialogState,
    })
  ),
);
