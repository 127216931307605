<div class="dialog-wrapper">

  <h1
    class="dialog-h1"
    i18n
  >
    Produkt löschen
  </h1>

  <div
    class="beleg-data"
    i18n
  >
    <div *ngIf="produktDto?.produktnummer">
      {{ produktDto?.produktnummer }}
    </div>

    <div *ngIf="produktDto?.produktbezeichnung">
      {{ produktDto?.produktbezeichnung }}
    </div>

    <div *ngIf="produktDto?.produktbeschreibung">
      {{ produktDto?.produktbeschreibung }}
    </div>

    <div *ngIf="produktDto?.ustProzentsatz?.prozentsatz">
      {{ produktDto?.ustProzentsatz?.prozentsatz | percent }} USt
    </div>

    <div class="hint">Ein gelöschtes Produkt kann nicht wiederhergestellt werden.</div>
  </div>

  <mat-divider></mat-divider>

  <div class="button-wrapper">

    <button
      class="delete-button"
      mat-raised-button
      i18n
      (click)="deleteProduktClick()"
    >
      Löschen
    </button>

    <button
      mat-flat-button
      i18n
      (click)="closeDialogClick()"
    >
      Abbrechen
    </button>
  </div>
</div>
