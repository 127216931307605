import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/states/app.state';
import {NGXLogger} from 'ngx-logger';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CreateProduktDialogActions} from '../../../store/actions/create-produkt-dialog.actions';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {InhaberEntitiesSelectors} from '@adnova/jf-ng-components';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ProduktEntitiesActions} from '../../../store/actions/produkt-entities.actions';
import {CreateProduktDialogSelectors} from '../../../store/selectors/create-produkt-dialog.selectors';
import {Option} from '@adnova/jf-ng-components/lib/generic/form-elements/form-fields/select/option/option.interface';
import {CreateProduktRequestDTO} from '../../../openapi/fakturierung-openapi';
import {FormInput} from '../../components/form/form-input.interface';
import {CreateProduktFormControls} from './create-produkt-form-controls.interface';
import {ProduktEntitiesSelectors} from '../../../store/selectors/produkt-entities.selectors';


@Component({
  selector: 'app-create-produkt-dialog',
  templateUrl: './create-produkt-dialog.component.html',
  styleUrls: ['./create-produkt-dialog.component.scss']
})
export class CreateProduktDialogComponent implements OnInit, OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();
  private inhaberId?: string;
  private _verfuegbareEinheiten?: Option[];
  private _umsatzsteuersaetze?: Option[];
  private _berechnenungsarten?: Option[];

  get berechnenungsarten(): Option[] {
    return this._berechnenungsarten || [];
  }

  get verfuegbareEinheiten(): Option[] {
    return this._verfuegbareEinheiten || [];
  }

  get umsatzsteuersaetze(): Option[] {
    return this._umsatzsteuersaetze || [];
  }

  public formControls: CreateProduktFormControls = {
    produktBezeichnung: new FormControl('', [Validators.required]),
    einheit: new FormControl(null, []),
    betragssumme: new FormControl(null, []),
    berechnungsart: new FormControl(null, [Validators.required]),
    ustProzentsatz: new FormControl(null, [Validators.required]),
    produktbeschreibung: new FormControl('', []),
    produktnummer: new FormControl(null, [Validators.required]),
  };

  public formGroup = new FormGroup(this.formControls);

  protected formInputs: FormInput = {
    title: 'Neues Produkt anlegen',
    cancelButtonLabel: 'Abbrechen',
  };

  constructor(
    private store: Store<AppState>,
    private logger: NGXLogger,
    private snackbar: MatSnackBar,
  ) {
  }

  ngOnInit() {

    // INFO: Inhaber ID
    this.store.select(InhaberEntitiesSelectors.currentInhaber).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(inhaber => {
      this.inhaberId = inhaber?.id;
    });

    // INFO: Einheiten
    this.store.select(CreateProduktDialogSelectors.verfuegbareEinheiten).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(einheiten => {
      this._verfuegbareEinheiten = einheiten?.map(einheit => ({
        id: einheit.einheitId,
        label: einheit.bezeichnung || '',
        value: einheit.einheitId?.toString() || '',
        isSelected: false,
      }));
    });

    // INFO: Ust Saetze
    this.store.select(CreateProduktDialogSelectors.verfuegbareUmsatzsteuersaetze).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(umsatzsteuersaetze => {
      this._umsatzsteuersaetze = umsatzsteuersaetze?.map<Option>(ust => ({
        id: ust.id,
        label: ust.prozentsatz + ' %',
        value: ust.id?.toString() || '',
        isSelected: false,
      }));
    });

    // INFO: Next Produktnummer
    this.store.select(ProduktEntitiesSelectors.nextProduktnummer).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(nextProduktnummer => {
      if (nextProduktnummer) {
        this.formControls.produktnummer.setValue(nextProduktnummer);
      }
    });

    // INFO: Berechnungsarten
    this.store.select(CreateProduktDialogSelectors.berechnungsarten).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(umsatzsteuersaetze => {
      this._berechnenungsarten = umsatzsteuersaetze?.map<Option>(
        source => ({
          value: source.id?.toString() || '',
          label: source.berechnungsart?.toString() || '',
          isSelected: source.berechnungsart == 'Netto',
          id: source.id,
        }));
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  protected createProductClick(): void {
    if (this.inhaberId) {
      this.store.dispatch(ProduktEntitiesActions.createProdukt({
        inhaberId: this.inhaberId,
        produktDto: this.createRequestDTO()
      }));
      this.store.dispatch(CreateProduktDialogActions.close());
    } else {
      this.logger.error('Fehler beim erstellen des Produkts: Inhaber ID nicht gesetzt');
    }
  }

  protected closeDialogClick(): void {
    this.store.dispatch(CreateProduktDialogActions.close());
  }

  protected createRequestDTO(): CreateProduktRequestDTO {
    return {
      produktbezeichnung: this.formControls.produktBezeichnung.value || '',
      einheitId: this.formControls.einheit.value?.find(obj => obj.isSelected)?.value || '',
      betragssumme: this.formControls.betragssumme.value || 0,
      berechnungsartId: this.formControls.berechnungsart.value?.find(obj => obj.isSelected)?.value || '',
      ustProzentsatzId: this.formControls.ustProzentsatz.value?.find(obj => obj.isSelected)?.value || '',
      produktbeschreibung: this.formControls.produktbeschreibung.value || '',
      produktnummer: this.formControls.produktnummer.value || 0,
    };
  }
}
