/**
 * Fakturierung
 * API für Fakturierung
 *
 * The version of the OpenAPI document: ${project.version}
 * Contact: support@landdata.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BerechnungsartDTO { 
    /**
     * Generierter Universally Unique Identifier
     */
    id?: string;
    /**
     * Berechnungsart des Produkts
     */
    berechnungsart?: BerechnungsartDTOBerechnungsartEnum;
}
export enum BerechnungsartDTOBerechnungsartEnum {
    Brutto = 'Brutto',
    Netto = 'Netto'
};



