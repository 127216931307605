import {AppState} from '../states/app.state';
import {identity} from 'rxjs';
import {createSelector} from '@ngrx/store';


export class CreateProduktDialogSelectors {

  /**
   * Die `select`-Eigenschaft ist ein Objekt, das Funktionen definiert, jede Funktion selektiert eine
   * bestimmte Eigenschaft aus dem State.
   * Das sind grundlegende Selektoren, die im ganzen Projekt als Bausteine für komplexere Selektoren wiederverwendet
   * werden können.
   */
  private static select = {
    isOpen: (state: AppState) => state.createProduktDialog.isOpen,
    produkt: (state: AppState) => state.createProduktDialog.produkt,
    verfuegbareEinheiten: (state: AppState) => state.createProduktDialog.availableEinheiten,
    verfuegbareUmsatzsteuersaetze: (state: AppState) => state.createProduktDialog.umsatzsteuersaetze,
    berechnungsarten: (state: AppState) => state.createProduktDialog.berechnungsarten,
  };

  public static isCreateProduktDialogOpen = createSelector(
    this.select.isOpen,
    identity,
  );

  public static createProdukt = createSelector(
    this.select.produkt,
    (produkt) => {
      return {
        produkt,
      };
    }
  );

  public static verfuegbareEinheiten = createSelector(
    this.select.verfuegbareEinheiten,
    identity,
  );

  public static verfuegbareUmsatzsteuersaetze = createSelector(
    this.select.verfuegbareUmsatzsteuersaetze,
    identity,
  );

  public  static berechnungsarten = createSelector(
    this.select.berechnungsarten,
    identity,
  );

  public static berechnungsartById = (id: string) => createSelector(
    this.select.berechnungsarten,
    (berechnungsarten) => {
      return (id: string) => {
        return berechnungsarten?.find(art => art.id === id);
      };
    }
  )
}
