import {Component, inject} from '@angular/core';
import {TableRowButtonsModule} from '../../../../components/table/table-row-buttons/table-row-buttons.module';
import {TableRowButton} from '../../../../components/table/table-row-buttons/table-row-button.interface';
import {FakturierungsbelegDTO} from '../../../../../openapi/fakturierung-openapi';
import {ELEMENT_DATA} from '../../../../components/table/table-wrapper/table/table.component';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../store/states/app.state';
import {NGXLogger} from 'ngx-logger';
import {
  DeleteFakturierungsbelegDialogActions
} from '../../../../../store/actions/delete-fakturierungsbeleg-dialog.actions';


@Component({
  standalone: true,
  selector: 'app-fakturierungsbeleg-actions.cell',
  templateUrl: './actions.cell.component.html',
  imports: [
    TableRowButtonsModule,
  ],
})
export class ActionsCellComponent {

  // INFO: Injections

  private store = inject(Store<AppState>);
  private logger = inject(NGXLogger);

  protected data?: FakturierungsbelegDTO = inject(ELEMENT_DATA);


  // INFO: Properties

  private readonly deleteButtonId = 'delete-beleg-button';

  protected primaryButton: TableRowButton = {
    id: this.deleteButtonId,
    tooltip: 'Löschen',
    iconName: 'basket',
  };


  // INFO: Methods

  tableRowButtonIdClick(buttonId: string) {
    if (!this.data?.id) return;

    switch (buttonId) {
      case this.deleteButtonId:
        // FIXME: Passende InhaberId und FakruierungsbelegId verwenden
        this.store.dispatch(DeleteFakturierungsbelegDialogActions.open({
          fakturierungbelegId: this.data.id,
        }));
        break;
      default:
        this.logger.warn('button with id not found: ', buttonId);
    }
  }

}
