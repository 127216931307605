<div class="dialog-wrapper">
  <app-form
    [formInput]="formInputs"
    (cancelClicked)="closeDialogClick()"
  >
    <div
      class='formfield-wrapper body-content'
    >
      <!--    Titel-->
      <jf-form-field-text
        [label]="'Produkt'"
        [formControl]="formControls.produktBezeichnung"
      ></jf-form-field-text>

      <div
        class='grouped-fields'>

        <!--    Einheit-->
        <jf-form-field-select
          [label]="'Einheit'"
          [formControl]="formControls.einheit"
          [options]="verfuegbareEinheiten"
        ></jf-form-field-select>

        <!--    Preis-->
        <jf-form-field-number
          [label]="'Preis in €'"
          [formControl]="formControls.betragssumme"
          [thousandSeparator]="'.'"
          [truncate]="2"
          [decimalSeparator]="','"
          [fillWithZeros]="true"
        ></jf-form-field-number>

      </div>

      <div
        class='grouped-fields'>

        <!--    Preisangabe In-->
        <jf-form-field-select
          [label]="'Preisberechnung in:'"
          [formControl]="formControls.berechnungsart"
          [options]="berechnenungsarten"
        ></jf-form-field-select>

        <!--    Umsatztsteuer-->
        <jf-form-field-select
          [label]="'Umsatzsteuersatz'"
          [formControl]="formControls.ustProzentsatz"
          [options]="umsatzsteuersaetze"
        ></jf-form-field-select>

      </div>

      <!--    Beschreibung-->
      <jf-form-field-text
        [formControl]="formControls.produktbeschreibung"
        [label]="'Produktbeschreibung'"
        [message]="'Produkt und Produktbeschreibung werden auf der Rechnung angezeigt'"
      ></jf-form-field-text>

      <!--    Produktnummer-->
      <jf-form-field-number
        [label]="'Produktnummer'"
        [formControl]="formControls.produktnummer"
        [message]="formControls.produktnummer.touched ? '' : 'Vorgeschlagene Produktnummer'"
      ></jf-form-field-number>
    </div>

    <div class="footer-content">
      <jf-button
        [variant]="'primary'"
        (click)="createProductClick()"
        [disabled]="!formGroup.valid"
      >Produkt anlegen</jf-button>
    </div>
  </app-form>
</div>
