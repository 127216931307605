import {AppState} from '../states/app.state';
import {identity} from 'rxjs';
import {createSelector} from '@ngrx/store';


export class BelegeEmptyStateDialogSelectors {

  /**
   * Die `select`-Eigenschaft ist ein Objekt, das Funktionen definiert, jede Funktion selektiert eine
   * bestimmte Eigenschaft aus dem State.
   * Das sind grundlegende Selektoren, die im ganzen Projekt als Bausteine für komplexere Selektoren wiederverwendet
   * werden können. BelegeEmptyStateDialog
   */
  private static select = {
    isOpen: (state: AppState) => state.belegeEmptyStateDialog.isOpen,
  };

 public static isBelegeEmptyStateDialogOpen = createSelector(
    this.select.isOpen,
    identity,
 );
}
