<div *ngIf="inhaberId !== undefined" class="icon-link-wrapper">
  <jf-link
    *ngFor="let navigationLink of navigationLinks"
    variant="textlink-icon"
    [routerLink]="[navigationLink.route, 'inhaber', inhaberId]"
    [text]="navigationLink.label"
    [iconName]="navigationLink.iconName"
    iconPosition="before"
    routerLinkActive="active"
    i18n-text
  ></jf-link>

  <!-- TODO: jf-button durch Button-Link ersetzen, sobald er verfügbar ist. -->
  <jf-button
    *ngIf="buttonAction && buttonLabel"
    [variant]="buttonVariant"
    [icon]="buttonIcon"
    [text]="buttonLabel"
    iconPosition="left"
    (click)="doButtonAction()"
  ></jf-button>
</div>

<div class="content-wrapper">
  <router-outlet></router-outlet>
</div>
