import {Component, OnInit} from '@angular/core';
import {AppState} from '../../../store/states/app.state';
import {Store} from '@ngrx/store';
import {AbsenderEntitiesSelectors} from '../../../store/selectors/absender-entities.selectors';
import {
  DeleteAbsenderDialogSelectors
} from '../../../store/selectors/delete-absender-dialog.selectors';
import {take} from 'rxjs';
import {AbsenderDTO} from '../../../openapi/fakturierung-openapi';
import {DeleteAbsenderDialogActions} from '../../../store/actions/delete-absender-dialog.actions';
import {NGXLogger} from 'ngx-logger';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'app-delete-absender-dialog',
  templateUrl: './delete-absender-dialog.component.html',
  styleUrls: ['./delete-absender-dialog.component.scss']
})
export class DeleteAbsenderDialogComponent implements OnInit {

  protected absenderDto?: AbsenderDTO;

  constructor(
    private store: Store<AppState>,
    private logger: NGXLogger,
    private snackbar: MatSnackBar,
  ) {
  }

  ngOnInit() {
    this.store.select(DeleteAbsenderDialogSelectors.deleteData).pipe(
      take(1),
    ).subscribe(data => {

      if (data.absenderId) {
        this.store.select(AbsenderEntitiesSelectors.absenderById(
          data.absenderId,
        )).pipe(
          take(1),
        ).subscribe(absenderDto => {
          this.absenderDto = absenderDto;
        });
      } else {
        this.logger.warn('delete dialog absenderId is not set');
      }
    });
  }

  protected deleteAbsenderClick(): void {
    this.snackbar.open('Kontakt löschen wurde geklickt', undefined, {
      duration: 1000,
    });

    this.store.dispatch(DeleteAbsenderDialogActions.close());
  }

  protected closeDialogClick(): void {
    this.store.dispatch(DeleteAbsenderDialogActions.close());
  }
}
