import {createAction} from '@ngrx/store';

export class BelegeEmptyStateDialogActions {
    public static open = createAction(
      '[Belege Emptystate Dialog] Open Emptystate Information Dialog.',
    );

    public static close = createAction(
      '[Belege Emptystate Dialog] Close Emptystate Information Dialog.',
    );
}
