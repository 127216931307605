import {AppState} from '../states/app.state';
import {identity} from 'rxjs';
import {createSelector} from '@ngrx/store';


export class DeleteProduktDialogSelectors {

  /**
   * Die `select`-Eigenschaft ist ein Objekt, das Funktionen definiert, jede Funktion selektiert eine
   * bestimmte Eigenschaft aus dem State.
   * Das sind grundlegende Selektoren, die im ganzen Projekt als Bausteine für komplexere Selektoren wiederverwendet
   * werden können.
   */
  private static select = {
    isOpen: (state: AppState) => state.deleteProduktDialog.isOpen,
    inhaberId: (state: AppState) => state.deleteProduktDialog.inhaberId,
    produktId: (state: AppState) => state.deleteProduktDialog.produktId,
  };

  public static isDeleteProduktDialogOpen = createSelector(
    this.select.isOpen,
    identity,
  );

  public static deleteData = createSelector(
    this.select.inhaberId,
    this.select.produktId,
    (inhaberId, produktId) => {
      return {
        inhaberId,
        produktId,
      };
    }
  );
}
